(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:UserCtrl
   *
   * @description
   *
   */
  angular
    .module('user')
    .controller('UserCtrl', UserCtrl);

  function UserCtrl($location, $scope, $localStorage, $rootScope, RCRUsers, Events, Alerts) {
    var vm = this;
    $scope.$storage = $localStorage;

    if(!RCRUsers.isAuthenticated()){$location.path('/');}

    var loggeInUser = $localStorage['loggedInUser'].id;

    RCRUsers.findOne({filter: {"where": {"id": loggeInUser}}}).$promise.then(function(results){
      vm.userDetails = results;
    });

    vm.updateUser = function (userData) {
		if (userData.password == ""){
			delete userData.password
		}
      userData.username = userData.email;
      RCRUsers.prototype$patchAttributes({id: userData.id}, userData,
        function(){
          //vm.success = true;
          Events.create({
			  "eventType":"update user", 
			  "eventData":userData, 
			  "userId":userData.id
		  });
          $scope.$storage.user.firstName = $scope.$storage.loggedInUser.firstName = userData.firstName;
          $scope.$storage.user.lastName = $scope.$storage.loggedInUser.lastName = userData.lastName;
          $scope.$storage.user.email = $scope.$storage.loggedInUser.email = userData.email;
          $scope.$storage.user.department = $scope.$storage.loggedInUser.department = userData.department;
		  $scope.$storage.user.phoneNumber = $scope.$storage.loggedInUser.phoneNumber = userData.phoneNumber
          $scope.$storage.user.headOfDepartment = $scope.$storage.loggedInUser.headOfDepartment = userData.headOfDepartment;
		  
		  Alerts.addAlert('ok','Your details have been successfully updated');
        },
        function(err){
			Alerts.addAlert('error','We encountered an error while trying to save your details: ' + err.data.error.message);
          console.log(err);
      });
    }
  }
}());
